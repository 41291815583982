import axios from 'axios';
import { metaConfig } from '../config/Url';

export const WhatsAppService = (phoneNumber, message) => {
  const WP_URL = `https://api.whatsapp.com/send?phone=51${phoneNumber}&text=${message}`;
  return WP_URL;
  // setTimeout(() => {
  //   window.open(WP_URL);
  // }, 2000);
};

export const notificacionWp = async ({ payload }) => {
  const data = {
    messaging_product: 'whatsapp',
    to: `51${payload.phone}`,
    type: 'template',
    template: {
      name: 'notificacion_fairdent',
      language: {
        code: 'es',
      },
      components: [
        {
          type: 'body',
          parameters: [
            {
              type: 'text',
              text: payload.emisor,
            },
            {
              type: 'text',
              text: payload.asunto,
            },
          ],
        },
      ],
    },
  };
  return axios
    .post(
      `https://graph.facebook.com/${metaConfig.versionWp}/${metaConfig.userIdWp}/messages`,
      data,
      {
        headers: { Authorization: `Bearer ${metaConfig.tokenWp}` },
      }
    )
    .then((response) => {
      return response;
    });
};

export const notificacionAnularTicketWp = async ({ payload }) => {
  const data = {
    messaging_product: 'whatsapp',
    to: `51${payload.phone}`,
    type: 'template',
    template: {
      name: 'notificacion_fairdent',
      language: {
        code: 'es',
      },
      components: [
        {
          type: 'body',
          parameters: [
            {
              type: 'text',
              text: payload.emisor,
            },
            {
              type: 'text',
              text: payload.asunto,
            },
          ],
        },
      ],
    },
  };
  return axios
    .post(
      `https://graph.facebook.com/${metaConfig.versionWp}/${metaConfig.userIdWp}/messages`,
      data,
      {
        headers: { Authorization: `Bearer ${metaConfig.tokenWp}` },
      }
    )
    .then((response) => {
      return response;
    });
};
export const notificacionMesapartesWp = async ({ payload }) => {
  // eslint-disable-next-line no-return-await
  const data = {
    messaging_product: 'whatsapp',
    recipient_type: 'individual',
    to: `51${payload.destinoUser.phone}`,
    type: 'template',
    template: {
      name: 'notificacion_mesa_partes',
      language: {
        code: 'es',
      },
      components: [
        {
          type: 'body',
          parameters: [
            {
              type: 'text',
              text: payload.destinoUser.full_name,
            },
            {
              type: 'text',
              text: `: *${payload.asunto}*`,
            },
            {
              type: 'text',
              text: 'https://fairsos.grupo-sos.com/app/mesapartes/seguimiento',
            },
          ],
        },
      ],
    },
  };
  const response = await axios.post(
    `https://graph.facebook.com/${metaConfig.versionWp}/${metaConfig.userIdWp}/messages`,
    data,
    {
      headers: { Authorization: `Bearer ${metaConfig.tokenWp}` },
    }
  );
  return response;
};

export const notificacionBienvenidoPaciennteWp = async (dataPaciente) => {
  // eslint-disable-next-line no-return-await
  console.log(dataPaciente);
  const data = {
    messaging_product: 'whatsapp',
    to: `51${dataPaciente.phone}`,
    type: 'template',
    template: {
      name: 'welcome_fairdent_paciente',
      language: {
        code: 'es',
      },
      components: [
        {
          type: 'header',
          parameters: [
            {
              type: 'image',
              image: {
                link:
                  'https://grupo-sos-uploads.s3.amazonaws.com/fairdent/fairdent-welcome.png',
              },
            },
          ],
        },
      ],
    },
  };
  return axios
    .post(
      `https://graph.facebook.com/${metaConfig.versionWp}/${metaConfig.userIdWp}/messages`,
      data,
      {
        headers: { Authorization: `Bearer ${metaConfig.tokenWp}` },
      }
    )
    .then((response) => {
      return response;
    });
};
export const notificacionOfertaFairdent = async (oferta) => {
  // eslint-disable-next-line no-return-await
  console.log(oferta);
  // const data = {
  //   messaging_product: 'whatsapp',
  //   to: `51${dataPaciente.phone}`,
  //   type: 'template',
  //   template: {
  //     name: 'welcome_fairdent_paciente',
  //     language: {
  //       code: 'es',
  //     },
  //     components: [
  //       {
  //         type: 'header',
  //         parameters: [
  //           {
  //             type: 'image',
  //             image: {
  //               link:
  //                 'https://grupo-sos-uploads.s3.amazonaws.com/fairdent/fairdent-welcome.png',
  //             },
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // };
  // return axios
  //   .post(
  //     `https://graph.facebook.com/${metaConfig.versionWp}/${metaConfig.userIdWp}/messages`,
  //     data,
  //     {
  //       headers: { Authorization: `Bearer ${metaConfig.tokenWp}` },
  //     }
  //   )
  //   .then((response) => {
  //     return response;
  //   });
};
export const notificacionOfertaTestFairdent = async (oferta) => {
  // eslint-disable-next-line no-return-await
  console.log(oferta);
  const data = {
    messaging_product: 'whatsapp',
    to: `51${oferta.numeroTest}`,
    type: 'template',
    template: {
      name: 'ofertas_fairdent',
      language: {
        code: 'es',
      },
      components: [
        {
          type: 'header',
          parameters: [
            {
              type: 'image',
              image: {
                link: oferta.pathImage,
              },
            },
          ],
        },
        {
          type: 'body',
          parameters: [
            {
              type: 'text',
              text: oferta.descripcion,
            },
          ],
        },
      ],
    },
  };
  const result = await axios
    .post(
      `https://graph.facebook.com/${metaConfig.versionWp}/${metaConfig.userIdWp}/messages`,
      data,
      {
        headers: { Authorization: `Bearer ${metaConfig.tokenWp}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
  return result;
};
