import React, { Fragment, useState } from 'react';
import {
  Col,
  Container,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import Loarder from '../../../../../components/Loader';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import Url from '../../../../../config/Url';
import SweetAlert from 'sweetalert2';
import moment from 'moment';

const Agendar = ({ loading, agendaText, setAgendaText }) => {
  const socket = useSelector((state) => state.Socket.socket);
  const role_id = useSelector((state) => state.Auth.data.role_user_id);
  const staff = useSelector((state) => state.Auth.data.id);
  const idSucursal = useSelector((state) => state.Auth.data.sucursal_id);
  const nombrePaciente = useSelector(
    (state) => state.PatientData.nombre_paciente
  );
  const token = useSelector((state) => state.Auth.data.access_token);
  const id_paciente = useSelector((state) => state.PatientData.id_paciente);
  const { id_cita } = useSelector((state) => state.PatientData.id_cita);

  const [disable, setDisable] = useState(false);
  const [buttonText, setButtonText] = useState('SOLICITAR CALIFICACION');

  const tratamientosPend = useSelector(
    (state) => state.esp_paciente_actualTratam.tratamientos
  );
  const handleCalificacion = () => {
    SweetAlert.fire({
      title: 'Estas seguro?',
      text: 'Se enviara la solicitud para calificarte!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, estoy seguro',
    }).then((result) => {
      if (result.isConfirmed) {
        setDisable(true);
        setButtonText('SOLICITUD ENVIADA');
        socket.emit('parlante-mensaje', {
          type: 'mensaje-personalizado',
          payload: {
            data: `${nombrePaciente} por favor acerquese a calificar a su especialista en el area de buzon de la clinica, gracias`,
            idSucursal,
          },
        });
        console.log(id_paciente);
        const data = {
          modalOpen: true,
          paciente_id: id_paciente,
          role_user_id: role_id,
          staff_id: staff,
          cita: id_cita,
          sucursal: idSucursal,
          nombrePac: nombrePaciente,
        };
        socket.emit('openModal', data);
        console.log('modal:', data);

        SweetAlert.fire(
          'Enviado!',
          'Tu solicitud se envio con exito',
          'success'
        );
      }
    });
  };
  const validarCitaforCalificacion = async () => {
    console.log(id_cita);
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const res = await Axios.post(
        `${Url}consultaCalificacion`,
        { id_cita, role_id, staff },
        config
      );
      const response = await res.data;
      if (response.success) {
        console.log('estadoCalificacion', response);
        if (response.estadoCalificacion?.pri_calificacion === 1) {
          handleCalificacion();
        } else {
          console.log(
            'no tiene autorizacion para que el paciente califique su atencion'
          );
        }
      } else {
        SweetAlert.fire(
          'Error!',
          'Tu solicitud ya fue enviada, no pueden calificarle dos veces por cita...',
          'error'
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          {loading ? (
            <Loarder />
          ) : (
            <Fragment>
              <FormGroup>
                <Label>
                  Seleccione el tratamiento que se realizara en la siguiente
                  sesión...
                </Label>
                <Input
                  value={agendaText.select}
                  onChange={(e) =>
                    setAgendaText({ ...agendaText, select: e.target.value })
                  }
                  type="select"
                  name="select"
                  id="exampleSelect"
                >
                  <option value="0">ESCOJA UN TRATAMIENTO</option>
                  {tratamientosPend.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.id_odon_tipo_tratamiento === null
                          ? item.odtr_trat_especificacion
                          : item.odtr_tipo_tratamiento}{' '}
                        - {moment(item.created_at).format('YYYY-MM-DD')}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Escriba la siguiente actividad para la cita</Label>
                <Input
                  required
                  value={agendaText.text}
                  onChange={(e) =>
                    setAgendaText({ ...agendaText, text: e.target.value })
                  }
                  placeholder="actividad siguiente..."
                  type="text"
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Seleccione el tiempo sugerido para la siguiente sesión
                </Label>
                <Input
                  required
                  value={agendaText.tiempo}
                  onChange={(e) =>
                    setAgendaText({ ...agendaText, tiempo: e.target.value })
                  }
                  type="select"
                >
                  <option value="0_min">Seleccione un tiempo</option>
                  <option value="15_min">15 min</option>
                  <option value="30_min">30 min</option>
                  <option value="45_min">45 min</option>
                  <option value="60_min">1 hr</option>
                  <option value="75_min">1hr 15min</option>
                  <option value="90_min">1hr 30min</option>
                  <option value="105_min">1hr 45min</option>
                  <option value="120_min">2hr</option>
                </Input>
              </FormGroup>
              {/* <Button onClick={handleCalificacion}>Calificame</Button> */}
              {/* <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  style={{
                    width: '100%',
                    marginBottom: '15px',
                    backgroundColor: 'crimson',
                    border: 'none',
                  }}
                  disabled={disable}
                  onClick={validarCitaforCalificacion}
                >
                  {buttonText}
                </Button>
              </div> */}
            </Fragment>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Agendar;
