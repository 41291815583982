import io from 'socket.io-client';
import { ServerSocket } from '../../config/Url';
import {
  deepOrange,
  deepPurple,
  amber,
  cyan,
  blue,
  purple,
  lime,
  yellow,
} from '@mui/material/colors';

const socket = io(ServerSocket);

const initialState = {
  socket,
  stateSocket: false,
};

const initialColors = [
  deepOrange[500],
  deepPurple[500],
  amber[500],
  cyan[500],
  blue[500],
  purple[500],
  lime[500],
  yellow[500],
];

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SOCKET_CONNECT': {
      const userid = action.payload.id;
      const userFullName = action.payload.full_name;
      const idSucursal = action.payload.sucursal_id;
      const IdRoles = action.payload.role_user_id;
      const role_name = action.payload.role;
      const randColorNum = Math.floor(Math.random() * initialColors.length);
      console.log('mandando datos de redux saga a socket');
      console.log('saga redux', role_name);
      const randColor = initialColors[randColorNum];
      socket.emit('new-user', {
        idUser: userid,
        name: userFullName,
        idSucursal,
        IdRoles,
        role_name,
        estado: 'ACTIVO',
        color: randColor,
      });
      return state;
    }

    case 'SOCKET_STATE': {
      return {
        ...state,
        stateSocket: action.payload,
      };
    }
    default:
      return state;
  }
};
