// const url = 'http://localhost/clinica_sos_back-2023/api/';
// export const apiStomax = 'http://localhost:7000/api/';
// export const ServerSocket = 'http://localhost:5000';
// export const UrlFile = 'http://localhost/clinica_sos_back-2023/';
// export const urlCanvas = 'http://localhost/canvas-html-js/';
// export const urlFingerPrint = 'http://localhost:3001';
export const facturaToken =
  '?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6Imp0b21va2lqQGdtYWlsLmNvbSJ9.ALhM-MzU82xH21aLQBqgfpKJQJPgtING2CTNAduny80';
export const sunatUrl = 'https://dniruc.apisperu.com/api/v1/ruc/';
export const reniecUrl = 'https://dniruc.apisperu.com/api/v1/dni/';

// const url = 'https://api.fairdent.grupo-sos.com/api/';
// export const apiStomax = 'https://api.stomax.grupo-sos.com/api/';
// export const ServerSocket = 'https://socket-sos.up.railway.app/';
// export const UrlFile = 'https://api.fairdent.grupo-sos.com/';
// export const urlCanvas = 'https://canvas.grupo-sos.com';
// export const urlFingerPrint = 'https://fingerprint.grupo-sos.com';

const url = process.env.REACT_APP_API_URL;
export const apiStomax = process.env.REACT_APP_API_STOMAX_URL;
export const ServerSocket = process.env.REACT_APP_SERVER_SOCKET_URL;
export const UrlFile = process.env.REACT_APP_FILE_URL;
export const urlCanvas = process.env.REACT_APP_CANVAS_URL;
export const urlFingerPrint = process.env.REACT_APP_FINGER_PRINT_URL;

export const metaConfig = {
  baseUrl: process.env.REACT_APP_META_GRAPH_BASE_URL,
  versionWp: process.env.REACT_APP_META_WHATSAPP_VERSION,
  userIdWp: process.env.REACT_APP_META_WHATSAPP_USER_ID,
  tokenWp: process.env.REACT_APP_META_WHATSAPP_USER_TOKEN,
};

export const serviceUrl = {
  baseURL: url,
};

export default url;
